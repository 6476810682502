/**
 * Smooth scroll when anchor link is clicked
 */
const anchorSmoothScroll = () => {
  const anchorLink = document.querySelectorAll("a.header__link[href^='#']")
  const header = document.querySelector('.header')

  for(let item of anchorLink) {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector(item.getAttribute('href')).scrollIntoView({ behavior: 'smooth' })
      header.classList.remove('header--active')
      document.body.classList.remove('overflow')
    });
  }
}

export default anchorSmoothScroll
