/**
 * Change the color randomly
 */
const randomColors = () => {
  let index = Math.floor(Math.random() * 5);
  let colors = [
    'blue',
    'violet',
    'pink',
    'green',
    'orange'
  ]

  document.body.classList.add(colors[index])
}

export default randomColors
