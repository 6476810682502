/**
 * Toggles the navigation menu
 */
const toggleNavigation = () => {
  const menu = document.querySelector('.header__menu')
  const header = document.querySelector('.header')

  menu.addEventListener('click', () => {
    document.body.classList.toggle('overflow')
    header.classList.toggle('header--active')
  })
}

export default toggleNavigation
