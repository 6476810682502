/**
 * Toggles the action button in will section
 */
const toggleActionButton = () => {
  const toggleAnchor = document.querySelectorAll('.js-show-click')
  const willActionInfoBlock = document.querySelectorAll('.will__action-info-block')
  const buttons = document.querySelectorAll('.contact__button')

  for(let [index, item] of toggleAnchor.entries()) {
    item.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      let activeClass = document.querySelector('.will__action-info-block--active')
      let activeClassButton = document.querySelector('.will__action-item--active')
      if(activeClass) {
        activeClass.classList.remove('will__action-info-block--active')
        activeClassButton.classList.remove('will__action-item--active')
      }
      if(activeClassButton === item) {
        document.querySelector(item.getAttribute('href')).classList.remove('will__action-info-block--active')
        item.classList.remove('will__action-item--active')
      } else {
        document.querySelector(item.getAttribute('href')).classList.add('will__action-info-block--active')
        item.classList.add('will__action-item--active')
      }
    })
    willActionInfoBlock[index].addEventListener('click', e => {
      e.stopPropagation()
      e.preventDefault()
    })
  }

  for(let item of buttons) {
    item.addEventListener('click', e => {
      e.stopPropagation()
    })
  }

  document.body.addEventListener('click', () => {
    let activeClass = document.querySelector('.will__action-info-block--active')
    let activeClassButton = document.querySelector('.will__action-item--active')
    if(activeClass) {
      activeClass.classList.remove('will__action-info-block--active')
      activeClassButton.classList.remove('will__action-item--active')
    }
  })
}

export default toggleActionButton
