import activeNavigation from './components/activeNavigation'
import anchorSmoothScroll from './components/anchorSmoothScroll'
import randomColors from './components/randomColors'
import toggleActionButton from './components/toggleActionButton'
import toggleNavigation from './components/toggleNavigation'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    randomColors()
    toggleNavigation()
    anchorSmoothScroll()
    toggleActionButton()
    activeNavigation()

    window.addEventListener('scroll', () => {
      activeNavigation()
    })
  },
  false
)
