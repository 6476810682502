/**
 * Add an active class when the user is viewing a specific section
 */
const activeNavigation = () => {
  let previousRefElement = null;
  const anchor = document.querySelectorAll("a.header__link[href^='#']")

  for (let i = 0; i < anchor.length; ++i) {
    let currentRefElement = document.getElementById(anchor[i].getAttribute('href').substring(1));
    let currentRefElementTop = currentRefElement.getBoundingClientRect().top - 60;

    if (currentRefElementTop <= 0) {
      previousRefElement = anchor[i];

      if (i == anchor.length - 1) {
        for (let j = 0; j < anchor.length; ++j) {
          anchor[j].classList.remove('header__link--active');
        }

        anchor[i].classList.add('header__link--active');
      }
    } else {
      for (let j = 0; j < anchor.length; ++j) {
        anchor[j].classList.remove('header__link--active');
      }

      if (previousRefElement !== null) {
        previousRefElement.classList.add('header__link--active');
      }

      break;
    }
  }
}

export default activeNavigation
